<!-- ***** Main Menu Area Start ***** -->
<div class="mainMenu d-flex align-items-center justify-content-between">
  <!-- Close Icon -->
  <div class="closeIcon">
    <i class="ti-close" aria-hidden="true"></i>
  </div>
  <!-- Logo Area -->
  <div class="logo-area">
    <a [routerLink]="['']">
      <img *ngIf="folder===''" src="assets/img/logo_petamedia_w_text.svg" class="img-fluid" style="max-height: 30px">
      <img *ngIf="folder!==''" src="assets/360/vtour/tours/{{folder}}/logo.png" class="img-fluid" style="max-height: 60px">
    </a>
  </div>
</div>
<!-- ***** Main Menu Area End ***** -->

<!-- ***** Header Area Start ***** -->
<header class="header-area" [class.p-0]="id === 'manhattan'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="menu-area d-flex justify-content-between">
          <!-- Logo Area  -->
          <div class="logo-area">
            <a [routerLink]="['']">
              <img *ngIf="folder===''" src="assets/img/logo_petamedia_w_text.svg" class="img-fluid" style="max-height: 30px">
              <img *ngIf="folder!==''" src="assets/360/vtour/tours/{{folder}}/logo.png" class="img-fluid" style="max-height: 60px">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- ***** Header Area End ***** -->
