import { Routes } from '@angular/router';

import { DefaultComponent } from './layout/default/default.component';
import { PanoComponent } from './pages/pano/pano.component';
import { Full360Component } from './layout/full-360/full-360.component';
import { Default2Component } from './layout/default2/default2.component';
import { Full360CustomComponent } from './layout/full-360-custom/full-360-custom.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
      }
    ]
  },
  {
    path: '',
    component: Full360Component,
    children: [
      {
        path: 'pano',
        loadChildren: () => import('./pages/pano/pano.module').then(m => m.PanoModule)
      }
    ]
  },
  {
    path: '',
    component: Full360CustomComponent,
    children: [
      {
        path: '360',
        loadChildren: () => import('./pages/p360/p360.module').then(m => m.P360Module)
      }
    ]
  },
  {
    path: '',
    component: Default2Component,
    children: [
      {
        path: 'project',
        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'about-us',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./pages/portfolio/portfolio.module').then(m => m.PortfolioModule)
      }
    ]
  }
];
