import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var jQuery: any;
declare var nowuiKit: any;

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {

  private last_page = '';

    constructor(
      private router: Router
    ) {
      /*router.events.subscribe((val) => {
        if (this.last_page !== window.location.href) {
          this.last_page = window.location.href;
          const toggle = jQuery(this);
          jQuery('html').removeClass('nav-open');
          nowuiKit.misc.navbar_menu_visible = 0;
          setTimeout(function() {
            toggle.removeClass('toggled');
            jQuery('#bodyClick').remove();
          }, 550);
        }
      });*/
    }

    ngOnInit() {
      jQuery('#menuIcon').on('click', function () {
        jQuery('body').toggleClass('menu-open');
      });
      jQuery('.closeIcon').on('click', function () {
        jQuery('body').removeClass('menu-open');
      });
    }
}
