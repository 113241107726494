<!-- ***** Main Menu Area Start ***** -->
<div class="mainMenu d-flex align-items-center justify-content-between">
  <!-- Close Icon -->
  <div class="closeIcon">
    <i class="ti-close" aria-hidden="true"></i>
  </div>
  <!-- Logo Area -->
  <div class="logo-area">
    <a [routerLink]="['']">
      <img src="assets/img/logo_petamedia_w_text.svg" class="img-fluid" style="max-height: 30px">
    </a>
  </div>
  <!-- Nav -->
  <div class="sonarNav wow fadeInUp" data-wow-delay="1s">
    <nav>
      <ul>
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="['']">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/portfolio']">Portfolio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/about-us']">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/contact-us']">Contact</a>
        </li>
      </ul>
    </nav>
  </div>
  <!-- Copwrite Text -->
  <div class="copywrite-text">
    <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
      Copyright &copy; PetaRumah<script>document.write(new Date().getFullYear());</script> All rights reserved | Made with <i class="fa fa-heart-o" aria-hidden="true"></i> by PetaRumah, <a href="https://colorlib.com" target="_blank">Colorlib</a>
      <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
    </p>
  </div>
</div>
<!-- ***** Main Menu Area End ***** -->

<!-- ***** Header Area Start ***** -->
<header class="header-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="menu-area d-flex justify-content-between">
          <!-- Logo Area  -->
          <div class="logo-area">
            <a [routerLink]="['']">
              <img src="assets/img/logo_petamedia_w_text.svg" class="img-fluid" style="max-height: 30px">
            </a>
          </div>

          <div class="menu-content-area d-flex align-items-center">
            <!-- Header Social Area -->
            <div class="header-social-area d-flex align-items-center">
              <a href="https://www.linkedin.com/company/peta-rumah" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
              <a href="https://www.instagram.com/petarumah" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a>
              <a href="https://web.facebook.com/PetaRumahID" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
              <a href="https://www.youtube.com/channel/UCmBtvsu-OO96_x28nRItoHA" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Youtube"><i class="fa fa-youtube" aria-hidden="true"></i></a>
            </div>
            <!-- Menu Icon -->
            <span class="navbar-toggler-icon" id="menuIcon"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- ***** Header Area End ***** -->
