import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-default2',
  templateUrl: './default2.component.html',
  styleUrls: ['./default2.component.css']
})
export class Default2Component implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // :: Nicescroll Active Code
    /*if (jQuery.fn.niceScroll) {
      jQuery('body, textarea').niceScroll({
        cursorcolor: '#151515',
        cursorwidth: '6px',
        background: '#f0f0f0'
      });
    }*/
  }

  getFullYear() {
    return new Date().getFullYear();
  }

}
