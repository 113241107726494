import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { DefaultComponent } from './layout/default/default.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { CommonModule } from '@angular/common';
import { PanoComponent } from './pages/pano/pano.component';
import { Full360Component } from './layout/full-360/full-360.component';
import { NavbarModule } from './layout/navbar/navbar.module';
import { Default2Component } from './layout/default2/default2.component';
import { Full360CustomComponent } from './layout/full-360-custom/full-360-custom.component';
import { NavbarCustomModule } from './layout/navbar-custom/navbar-custom.module';
import { FooterComponent } from './layout/footer/footer.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    Default2Component,
    Full360Component,
    Full360CustomComponent,
    PanoComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      onSameUrlNavigation: 'reload'
    }),
    NavbarModule,
    NavbarCustomModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
