<!-- ***** Footer Area Start ***** -->
<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Copywrite Text -->
        <div class="copywrite-text">
          <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            Copyright &copy; PetaRumah<script>document.write(new Date().getFullYear());</script> All rights reserved | Made with <i class="fa fa-heart-o" aria-hidden="true"></i> by PetaMedia, <a href="https://colorlib.com" target="_blank">Colorlib</a>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- ***** Footer Area End ***** -->
