import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var embedpano: any;
declare var removepano: any;
declare var jQuery: any;

@Component({
  selector: 'app-pano',
  templateUrl: './pano.component.html',
  styleUrls: ['./pano.component.css']
})
export class PanoComponent implements OnInit, OnDestroy {

  private id: string;
  private sub: any;
  public destroyed = new Subject<any>();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    /*this.id = this.activatedRoute.snapshot.params.id;
    this.loadPano();
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.id = this.activatedRoute.snapshot.params.id;
      this.loadPano();
    });*/


    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      console.log(this.id);
      this.loadPano();
    });

    jQuery('html').addClass('h-100');
    jQuery('body').addClass('h-100');
    jQuery('app-root').addClass('h-100');
  }

  loadPano(): void {
    removepano('pano1');
    switch (this.id) {
      case '1':
        embedpano({xml: './assets/360/vtour/tours/reiz_1br/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '2':
        embedpano({xml: './assets/360/vtour/tours/reiz_2br/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '3':
        embedpano({xml: './assets/360/vtour/tours/reiz_1br_multires/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '4':
        embedpano({xml: './assets/360/vtour/tours/kenanga_palace/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '5':
        embedpano({xml: './assets/360/vtour/tours/royal_sumatra/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '6':
        embedpano({xml: './assets/360/vtour/tours/cut_mutia/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '7':
        embedpano({xml: './assets/360/vtour/tours/manhattan/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '8':
        embedpano({xml: './assets/360/vtour/tours/maimoon/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '9':
        embedpano({xml: './assets/360/vtour/tours/rahmat_gallery/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      case '10':
        embedpano({xml: './assets/360/vtour/tours/wahid/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
      default:
        embedpano({xml: './assets/360/vtour/tours/reiz_1br/tour.xml', id: 'pano1',
          basepath: './assets/360/vtour/', target: 'pano', onready: this.krpanoReady});
        break;
    }
  }

  ngOnDestroy(): void {
    jQuery('html').removeClass('h-100');
    jQuery('body').removeClass('h-100');
    jQuery('app-root').removeClass('h-100');

    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
    this.destroyed.next();
    this.destroyed.complete();
  }

  krpanoReady(krpano): void {
    // krpano.set('fullscreen', true);
  }

}
