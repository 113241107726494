import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var jQuery: any;
declare var nowuiKit: any;

declare var $: any;
@Component({
    selector: 'app-navbar-custom-cmp',
    templateUrl: 'navbar-custom.component.html'
})

export class NavbarCustomComponent implements OnInit {

  public id: string;
  public folder = '';
  private last_page = '';

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {
      this.activatedRoute.params.subscribe(params => {
        console.log();
        this.id = params.id;
        switch (this.id) {
          case 'royalsumatra':
            this.folder = 'royal_sumatra';
            break;
          case 'manhattan':
            this.folder = 'manhattan';
            break;
        }
        console.log(this.folder);
      });
      router.events.subscribe((val) => {
        if (this.last_page !== window.location.href) {
          this.last_page = window.location.href;
          const toggle = jQuery(this);
          jQuery('html').removeClass('nav-open');
          nowuiKit.misc.navbar_menu_visible = 0;
          setTimeout(function() {
            toggle.removeClass('toggled');
            jQuery('#bodyClick').remove();
          }, 550);
        }
      });
    }

    ngOnInit() {
    }
}
