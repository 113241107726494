import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    const that = this;
  }

  ngAfterViewInit() {
    // :: Nicescroll Active Code
    /*if (jQuery.fn.niceScroll) {
      jQuery('body, textarea').niceScroll({
        cursorcolor: '#151515',
        cursorwidth: '6px',
        background: '#f0f0f0'
      });
    }*/
  }

  getFullYear() {
    return new Date().getFullYear();
  }
}
